import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '@wam/icons';

import { IconButtonComponent } from './icon-button.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [IconButtonComponent],
  imports: [CommonModule, IconModule, RouterModule],
  exports: [IconButtonComponent],
})
export class IconButtonModule {}
